import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";

const i18nRoot = "toggle_with_loader";

export const STATES = {
  CHECKED: "checked",
  UNCHECKED: "unchecked",
  LOADING: "loading",
};

export const Container = ({
  state = STATES.UNCHECKED,
  isDisabled,
  tooltipLabelChecked,
  tooltipLabelUnchecked,
  className: classNameProp,
  children,
}) => {
  const isChecked = state === STATES.CHECKED;
  const isLoading = state === STATES.LOADING;
  const isUnChecked = state === STATES.UNCHECKED;

  const className = classNames(
    "relative inline-flex cursor-pointer rounded-full border h-8 bg-primary items-center transition-colors min-w-[3.75rem]",
    {
      "border-v2-success hover:bg-tertiary": isChecked,
      "border-dark hover:bg-tertiary": isUnChecked,
      "border-dark": isLoading,
      "cursor-not-allowed": isDisabled,
    },
    classNameProp,
  );

  if (!tooltipLabelChecked || !tooltipLabelUnchecked) {
    return <div className={className}>{children}</div>;
  }

  const tooltipContent = isLoading
    ? t([i18nRoot, "loading"])
    : isChecked
    ? tooltipLabelChecked
    : tooltipLabelUnchecked;

  return (
    <TippyV2 className={className} content={tooltipContent} placement="top">
      {children}
    </TippyV2>
  );
};

Container.propTypes = {
  state: PropTypes.oneOf(Object.values(STATES)),
  isDisabled: PropTypes.bool,
  tooltipLabelChecked: PropTypes.string,
  tooltipLabelUnchecked: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
