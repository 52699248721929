import classnames from "classnames";
import type { TdProps } from "./interfaces";

export const Td = ({
  colSpan,
  className,
  onClick,
  children,
  ...rest
}: TdProps) => (
  <td
    colSpan={colSpan}
    className={classnames(
      "border-primary break-words border-b px-4 py-5 align-top first:pl-0 last:pr-0",
      {
        "cursor-pointer": !!onClick,
      },
      className,
    )}
    onClick={onClick}
    {...rest}
  >
    {children}
  </td>
);
