import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import mux from "mux-embed";
import { mediaTranscriptPaths } from "@circle-react/helpers/urlHelpers";

export const HtmlVideoPlayer = ({
  playerRef,
  src,
  poster,
  type,
  metadata = {},
  rounded = true,
  isInset = false,
  mediaTranscriptId,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      const initTime = Date.now();
      mux.monitor(videoRef.current, {
        debug: false,
        data: {
          ...metadata,
          env_key: window.MUX_DATA_KEY,
          player_init_time: initTime,
        },
      });
    }
    if (playerRef) {
      playerRef.current = videoRef.current;
    }
  }, [metadata, playerRef, videoRef]);

  return (
    <video
      className={classnames("", {
        "rounded-md": rounded && !isInset,
        "h-full w-full bg-black": isInset,
      })}
      controls
      controlsList="nodownload"
      preload="metadata"
      ref={videoRef}
      poster={poster}
    >
      <source src={src} type={type} />
      {mediaTranscriptId && (
        <track
          kind="captions"
          src={mediaTranscriptPaths.showVtt({ id: mediaTranscriptId })}
        />
      )}
    </video>
  );
};

HtmlVideoPlayer.propTypes = {
  playerRef: PropTypes.object,
  src: PropTypes.string.isRequired,
  poster: PropTypes.string,
  type: PropTypes.string.isRequired,
  metadata: PropTypes.object,
  rounded: PropTypes.bool,
  isInset: PropTypes.bool,
  mediaTranscriptId: PropTypes.number,
};
