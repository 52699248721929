import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { formatNotificationsCount } from "./utils";

interface BadgeIndicatorProps {
  count: number;
  dataTestId?: string;
}

export const BadgeIndicator = ({ count, dataTestId }: BadgeIndicatorProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames("pointer-events-none absolute z-10", {
        "-right-1 -top-1": !isV3Enabled,
        "right-0.5 top-0.5": isV3Enabled,
      })}
      data-testid={dataTestId}
    >
      <span
        className={classNames(
          "bg-c-sidebar-badge text-c-sidebar-badge-text  absolute right-0 top-0 inline-flex rounded-full",
          {
            "border-c-header-badge-indicator border": !isV3Enabled,
          },
        )}
      >
        <span className="inline-block min-h-[1rem] min-w-[1rem] px-1 text-center text-[0.625rem] font-semibold leading-4">
          {formatNotificationsCount(count)}
        </span>
      </span>
    </div>
  );
};
