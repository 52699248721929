import PropTypes from "prop-types";
import { flexRender } from "@tanstack/react-table";
import classnames from "classnames";
import { isFunction } from "lodash";
import { Table } from "@circle-react-uikit/TableV2";
import { Typography } from "@circle-react-uikit/Typography";
import { useDataTableDataProvider } from "../Provider/DataTableDataProvider";

export const Body = ({ className }) => {
  const { table, isLoading, onClickRow } = useDataTableDataProvider();
  const { rows } = table.getRowModel();

  return (
    <Table.TableBody
      className={classnames(className, {
        "opacity-30": isLoading,
      })}
    >
      {rows.map(row => (
        <Table.Tr
          key={row.id}
          {...(isFunction(onClickRow) && {
            onClick: () => onClickRow(row),
          })}
        >
          {row.getVisibleCells().map(cell => (
            <Table.Td
              key={cell.id}
              className={classnames(cell.column.columnDef.cellClassName, {
                "!py-3": row.getIsGrouped(),
                "first:!pl-4": row.depth === 1,
              })}
              {...(isFunction(cell.column.columnDef.onClick) && {
                onClick: event =>
                  cell.column.columnDef.onClick({ cell, event }),
              })}
            >
              <Typography.LabelSm>
                {cell.getIsAggregated()
                  ? flexRender(
                      cell.column.columnDef.aggregatedCell,
                      cell.getContext(),
                    )
                  : flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Typography.LabelSm>
            </Table.Td>
          ))}
        </Table.Tr>
      ))}
    </Table.TableBody>
  );
};

Body.propTypes = {
  className: PropTypes.string,
  onClickRow: PropTypes.func,
};
