import PropTypes from "prop-types";
import { TranscriptsPlayer } from "../TranscriptsPlayer";
import { HtmlVideoPlayer } from "./HtmlVideoPlayer";

export const PLAYER_VARIANTS = Object.freeze({
  DEFAULT: "default",
  ENHANCED: "enhanced",
});

export const VideoPlayer = ({
  src,
  poster,
  type,
  metadata = {},
  rounded = true,
  mediaTranscriptId,
  variant = PLAYER_VARIANTS.DEFAULT,
  signedId,
  isDownloadable,
}) => {
  if (variant === PLAYER_VARIANTS.ENHANCED) {
    return (
      <TranscriptsPlayer
        src={src}
        poster={poster}
        type={type}
        metadata={metadata}
        contentType={type}
        isDownloadable={isDownloadable}
        mediaTranscriptId={mediaTranscriptId}
        signedId={signedId}
      />
    );
  }

  return (
    <HtmlVideoPlayer
      src={src}
      poster={poster}
      type={type}
      metadata={metadata}
      rounded={rounded}
      mediaTranscriptId={mediaTranscriptId}
    />
  );
};

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  poster: PropTypes.string,
  type: PropTypes.string.isRequired,
  metadata: PropTypes.object,
  rounded: PropTypes.bool,
  mediaTranscriptId: PropTypes.number,
  variant: PropTypes.oneOf(Object.values(PLAYER_VARIANTS)),
};
