import { useRef } from "react";
import { t } from "i18n-js";
import { useHotkeys } from "react-hotkeys-hook";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "../../IconButton";

export const BookmarksPopoverButton = () => {
  const hasBookmarksButtonRef = useRef<HTMLButtonElement | null>(null);
  const { isV3Enabled } = useIsV3();

  useHotkeys(
    "alt+b",
    () => {
      hasBookmarksButtonRef.current?.click();
    },
    [hasBookmarksButtonRef],
  );

  return (
    <div className="relative">
      <TippyV2 interactive={false} content={t("header_v3.bookmarks")}>
        <IconButton
          name={isV3Enabled ? "20-flux-bookmark" : "16-bookmark"}
          buttonSize={isV3Enabled ? 36 : 28}
          iconSize={isV3Enabled ? 20 : 16}
          className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover rounded-md p-1.5"
          iconClassName="!text-c-header"
          ariaLabel={t("header_v3.bookmarks")}
          ref={hasBookmarksButtonRef}
          dataTestId="bookmarks-popover-button"
        />
      </TippyV2>
    </div>
  );
};
