import PropTypes from "prop-types";
import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { Checkmark } from "./Checkmark";
import { STATES as CONTAINER_STATES, Container } from "./Container";
import { Label } from "./Label";
import { Loader } from "./Loader";

export const ToggleWithLoader = ({
  isChecked,
  isLoading,
  isDisabled,
  onChange,
  checkedLabel,
  uncheckedLabel,
  tooltipCheckedLabel,
  tooltipUncheckedLabel,
  className,
  containerClassName,
  ...rest
}) => (
  <Switch
    checked={isChecked}
    onChange={onChange}
    className={classNames("flex", containerClassName)}
    disabled={isDisabled || isLoading}
    {...rest}
  >
    {({ checked: isChecked }) => (
      <Container
        state={
          isLoading
            ? CONTAINER_STATES.LOADING
            : isChecked
            ? CONTAINER_STATES.CHECKED
            : CONTAINER_STATES.UNCHECKED
        }
        isDisabled={isDisabled}
        tooltipLabelChecked={tooltipCheckedLabel}
        tooltipLabelUnchecked={tooltipUncheckedLabel}
        className={classNames(className, {
          "cursor-not-allowed opacity-50": isDisabled,
        })}
      >
        <span className="sr-only">
          {isChecked ? checkedLabel : uncheckedLabel}
        </span>

        <span
          className={classNames("left-0 flex w-full px-2", {
            "justify-start": isChecked,
            "justify-end": !isChecked,
            "opacity-0": isLoading,
          })}
        >
          <Label isVisible={Boolean(isChecked && !isLoading && checkedLabel)}>
            {checkedLabel}
          </Label>
          <Label
            isVisible={Boolean(!isChecked && !isLoading && uncheckedLabel)}
          >
            {uncheckedLabel}
          </Label>
        </span>

        {!isLoading && (
          <Checkmark isLoading={isLoading} isChecked={isChecked} />
        )}

        {isLoading && <Loader />}
      </Container>
    )}
  </Switch>
);
ToggleWithLoader.propTypes = {
  isChecked: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  checkedLabel: PropTypes.string,
  uncheckedLabel: PropTypes.string,
  tooltipCheckedLabel: PropTypes.string,
  tooltipUncheckedLabel: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};
