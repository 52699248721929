import { useMemo } from "react";
import { t } from "i18n-js";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { DIRECT_CHAT_ROOM } from "@/react/components/constants";
import { useWebSocket } from "@/react/hooks/useWebSocket";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { usePunditUserContext } from "@circle-react/contexts";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { useChatsSidebarApi } from "@circle-react/hooks/chatsV2/useChatsSidebarApi";
import { ErrorBoundary } from "@circle-react-uikit/ErrorBoundary";
import { Loader } from "@circle-react-uikit/Loader";
import { usePopoverPortalContext } from "@circle-react-uikit/PopoverPortal";
import { Item } from "./Item";
import { ItemWrapper } from "./Item/ItemWrapper";
import { NoMessages } from "./Item/NoMessages";
import { ListWrapper } from "./ListWrapper";

const MAX_UNREAD_UUIDS = 2000;

export const List = ({ unreadChatRoomUuids }) => {
  const { uuid: chatRoomUuid } = useParams();
  const { onClose } = usePopoverPortalContext() || {};
  const {
    currentCommunityMember,
    isLoading: isCommunityLoading,
    currentCommunitySettings,
  } = usePunditUserContext();

  const { pinned_dms_enabled: isPinnedDMsEnabled } =
    currentCommunitySettings || {};
  const {
    chatRooms,
    hasNextPage,
    isLoading: isChatRoomsLoading,
    onEventReceive,
    fetchNextPage,
  } = useChatsSidebarApi({
    openChatRoomUuid:
      chatRoomUuid && chatRoomUuid !== "new" ? chatRoomUuid : null,
    uuids: unreadChatRoomUuids?.slice(0, MAX_UNREAD_UUIDS),
  });

  useWebSocket(
    {
      channel: "ChatRoomChannel",
      onMessageReceive: data => {
        onEventReceive(data, currentCommunityMember?.id);
      },
      community_member_id: currentCommunityMember?.id,
      canCreateConnection: true,
    },
    [chatRooms],
  );

  const isLoading = isCommunityLoading || isChatRoomsLoading;

  const chatRoomWithMessages = useMemo(
    () => chatRooms.filter(chatRoom => chatRoom.last_message),
    [chatRooms],
  );

  if (isLoading) {
    return (
      <ListWrapper>
        <Loader center />
      </ListWrapper>
    );
  }

  if (!chatRoomWithMessages.length) {
    return (
      <ListWrapper>
        <NoMessages />
      </ListWrapper>
    );
  }
  return (
    <ListWrapper>
      <InfiniteScroll
        style={{ height: "100%", overflow: "hidden" }}
        scrollThreshold={0.8}
        next={fetchNextPage}
        hasMore={hasNextPage}
        dataLength={chatRoomWithMessages.length}
        scrollableTarget="scrollable-dms-div"
        loader={<Loader center />}
      >
        {chatRoomWithMessages.map(
          ({
            chat_room_name,
            chat_room_kind,
            last_message,
            current_participant = {},
            other_participants_preview: otherParticipants = [],
            id,
            unread_messages_count,
            uuid,
            pinned_at,
          }) => {
            const isDirect = chat_room_kind === DIRECT_CHAT_ROOM;
            const isPinned = isPinnedDMsEnabled && !!pinned_at;
            return (
              <ItemWrapper key={id} onClose={onClose} uuid={uuid}>
                <Item
                  chatRoomName={chat_room_name}
                  lastMessage={last_message}
                  currentParticipant={current_participant}
                  otherParticipants={otherParticipants}
                  isDirect={isDirect}
                  unreadMessagesCount={unread_messages_count}
                  timestamp={dateStringToTimeAgo(last_message?.created_at, {
                    format: "short",
                  })}
                  currentCommunityMember={currentCommunityMember}
                  isPinned={isPinned}
                />
              </ItemWrapper>
            );
          },
        )}
      </InfiniteScroll>
    </ListWrapper>
  );
};

const ChatError = () => (
  <div className="flex h-full w-full flex-col items-center justify-center">
    <Typography.LabelSm>{t("chat_space.error_message")}</Typography.LabelSm>
  </div>
);

export const DMsList = props => (
  <ErrorBoundary renderFunc={ChatError}>
    <List {...props} />
  </ErrorBoundary>
);
