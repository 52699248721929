import type { ReactNode } from "react";
import classNames from "classnames";

export interface DropdownWrapperProps {
  children: ReactNode;
  className?: string;
  dataTestId?: string;
}

export const DropdownWrapper = ({
  children,
  className,
  dataTestId,
}: DropdownWrapperProps) => (
  <div
    className={classNames("flex flex-row items-center space-x-px", className)}
    data-testid={dataTestId}
  >
    {children}
  </div>
);
