import { useMemo } from "react";
import type { HeaderNavItem } from "@circle-react/components/SettingsApp/Navigation/hooks/useFetchHeaderNavItems";
import { useNavigationBar } from "../useNavigationBar";
import { transformHeaderNavItemsToNavItems } from "../utils/transformHeaderNavItemsToNavItems";

interface UseTransformedNavItemsParams {
  headerNavItems: HeaderNavItem[];
  isPreviewMode: boolean;
  homeLinkTo: string;
  isHomeLinkActive: boolean;
  isCommunityAdmin: boolean;
}

type NavItem = ReturnType<typeof transformHeaderNavItemsToNavItems>[number];

const isEnabled = (item: NavItem) => item.visibility === "enabled";
const isDisabled = (item: NavItem) => item.visibility === "disabled";
const isAdminOnly = (item: NavItem) => item.visibility === "admin_only";
const isItemOfKind = (kind: string) => (item: NavItem) => item.kind === kind;

const useAdminFilterLogic = () => {
  const { shouldShowLeaderboardLink } = useNavigationBar();

  return (item: NavItem) =>
    isItemOfKind("leaderboard")(item)
      ? shouldShowLeaderboardLink && !isDisabled(item)
      : !isDisabled(item);
};

const useMemberFilterLogic = () => {
  const {
    shouldShowMembersLink,
    shouldShowAllEventsLink,
    shouldShowCoursesLink,
    shouldShowLeaderboardLink,
  } = useNavigationBar();

  return (item: NavItem) => {
    if (isItemOfKind("members")(item)) {
      return shouldShowMembersLink && isEnabled(item);
    }
    if (isItemOfKind("events")(item)) {
      return shouldShowAllEventsLink && isEnabled(item);
    }
    if (isItemOfKind("courses")(item)) {
      return shouldShowCoursesLink && isEnabled(item);
    }
    if (isItemOfKind("leaderboard")(item)) {
      return shouldShowLeaderboardLink && isEnabled(item);
    }
    return !isDisabled(item) && !isAdminOnly(item);
  };
};

const mapNavItem =
  (isPreviewMode: boolean, isHomeLinkActive: boolean) => (item: NavItem) => ({
    linkTo: item.url,
    label: item.name,
    isPresent: true,
    isDisabled: isPreviewMode,
    isActive: isItemOfKind("home")(item) ? isHomeLinkActive : false,
    kind: item.kind,
    visibility: item.visibility,
    openInNewWindow: item.open_in_new_window,
  });

export const useTransformedNavItems = ({
  headerNavItems,
  isPreviewMode,
  homeLinkTo,
  isHomeLinkActive,
  isCommunityAdmin,
}: UseTransformedNavItemsParams) => {
  const adminFilterLogic = useAdminFilterLogic();
  const memberFilterLogic = useMemberFilterLogic();

  return useMemo(() => {
    const navItems = transformHeaderNavItemsToNavItems(
      headerNavItems,
      homeLinkTo,
    );
    const filterLogic = isCommunityAdmin ? adminFilterLogic : memberFilterLogic;

    return navItems
      .filter(filterLogic)
      .map(mapNavItem(isPreviewMode, isHomeLinkActive));
  }, [
    headerNavItems,
    isPreviewMode,
    homeLinkTo,
    isHomeLinkActive,
    isCommunityAdmin,
    adminFilterLogic,
    memberFilterLogic,
  ]);
};
