import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { t } from "i18n-js";
import type {
  HeaderNavItemKind,
  HeaderNavItemVisibility,
} from "@circle-react/components/SettingsApp/Navigation/hooks/useFetchHeaderNavItems";
import { useSidebarBorderStyles } from "@circle-react/components/Spaces/SidebarV2/hooks";
import { Icon } from "@circle-react-shared/Icon";
import { popOverInOut } from "@circle-react-shared/uikit/animations";
import { NavigationLink } from "../NavigationItem";

const i18nRoot = "header_v3.navigation";

interface Item {
  linkTo: string;
  kind: HeaderNavItemKind;
  openInNewWindow?: boolean;
  label: string;
  visibility: HeaderNavItemVisibility;
}
export interface MorePopoverProps {
  items: Item[];
  isPreviewMode?: boolean;
}

export const MorePopover = ({
  items,
  isPreviewMode = false,
}: MorePopoverProps) => {
  const { borderColor } = useSidebarBorderStyles();

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            disabled={isPreviewMode}
            className={classNames(
              "text-xs-plus h-8.5 focus-visible:outline-secondary flex items-center !rounded-full py-2 pl-4 pr-2 font-medium transition-colors duration-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
              "hover:bg-c-header-hover text-c-header hover:text-c-header focus:text-c-header focus:bg-c-header-hover",
              {
                "bg-c-header-hover": open,
              },
            )}
          >
            <span className="flex gap-0.5">
              {t([i18nRoot, "more"])}
              <Icon type="12-chevron-down-v3" size={12} />
            </span>
          </Popover.Button>
          <Transition {...popOverInOut}>
            <Popover.Panel
              className="bg-c-header absolute right-0 top-1.5 flex w-max flex-col rounded-2xl border p-4 shadow-lg"
              style={{ borderColor }}
            >
              {items.map(
                ({ linkTo, label, kind, visibility, openInNewWindow }) => (
                  <NavigationLink
                    key={linkTo}
                    label={label}
                    linkTo={linkTo}
                    isFullRounded={false}
                    isActive={false}
                    onClick={close}
                    kind={kind}
                    visibility={visibility}
                    openInNewWindow={openInNewWindow}
                  />
                ),
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
