import { notificationActionIcon } from "@circle-react/components/Notifications/NotificationsList/List/Actions/ActionContent";
import type { Notification } from "@circle-react/types/Notification";
import { Icon } from "@circle-react-shared/Icon";

export interface NotificationTypeIconProps {
  notification: Notification;
}

export const NotificationTypeIcon = ({
  notification,
}: NotificationTypeIconProps) => {
  const notificationIconType: any = notificationActionIcon(notification);

  if (!notificationIconType) {
    return null;
  }

  return (
    <div className="notifications__icon !left-5 !top-5 !h-4 !w-4">
      <Icon type={notificationIconType} className="!w-3" size={24} />
    </div>
  );
};
