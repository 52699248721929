import { useRef } from "react";
import { t } from "i18n-js";
import { useHotkeys } from "react-hotkeys-hook";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { BadgeIndicator } from "@circle-react-shared/uikit/HeaderV3/RightActionBlock/BadgeIndicator";

export interface NotificationPopoverButtonProps {
  notificationCount: number;
}

export const NotificationPopoverButton = ({
  notificationCount,
}: NotificationPopoverButtonProps) => {
  const notificationsButtonRef = useRef<HTMLButtonElement | null>(null);
  const { isV3Enabled } = useIsV3();

  useHotkeys(
    "alt+n",
    () => {
      notificationsButtonRef?.current?.click();
    },
    [notificationsButtonRef],
  );

  return (
    <div className="relative">
      <TippyV2 interactive={false} content={t("header_v3.notifications")}>
        <IconButton
          name={isV3Enabled ? "20-bell-v3" : "16-notifications"}
          buttonSize={isV3Enabled ? 36 : 28}
          iconSize={isV3Enabled ? 20 : 16}
          className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover rounded-md"
          iconClassName="!text-c-header"
          ariaLabel={t("header_v3.notifications")}
          dataTestId="notifications-menu-popover-button"
          ref={notificationsButtonRef}
        />
        {notificationCount > 0 && (
          <BadgeIndicator
            count={notificationCount}
            dataTestId="unread-notifications-count"
          />
        )}
      </TippyV2>
    </div>
  );
};
