import { useMemo } from "react";
import { useFetchHeaderNavItems } from "@circle-react/components/SettingsApp/Navigation/hooks/useFetchHeaderNavItems";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useRouteHelpers } from "@circle-react/routes/Main/useRouteHelpers";
import { MorePopover } from "./MorePopover";
import { NavigationLink } from "./NavigationItem";
import { SkeletonLoader } from "./SkeletonLoader";
import { useTransformedNavItems } from "./hooks/useTransformedNavItems";
import { useNavigationBar } from "./useNavigationBar";

export interface NavigationBarProps {
  isPreviewMode?: boolean;
}

export const NavigationBar = ({
  isPreviewMode = false,
}: NavigationBarProps) => {
  const { currentCommunity, currentCommunityMember } = usePunditUserContext();
  const { homeLinkTo, maxVisibleLinks } = useNavigationBar();

  const { data: headerNavItems, isLoading: isLoadingHeaderNavItems } =
    useFetchHeaderNavItems({
      id: currentCommunity?.id,
    });

  const {
    isFeedRoute,
    isSpaceRoute,
    isSpaceGroupRoute,
    isGettingStartedRoute,
  } = useRouteHelpers();

  const isHomeLinkActive =
    isPreviewMode ||
    isSpaceRoute ||
    isSpaceGroupRoute ||
    isFeedRoute ||
    isGettingStartedRoute;

  const links = useTransformedNavItems({
    headerNavItems: headerNavItems ?? [],
    isPreviewMode,
    homeLinkTo,
    isHomeLinkActive,
    isCommunityAdmin: isCommunityAdmin(currentCommunityMember),
  });

  const { visibleLinks, hiddenLinks } = useMemo(() => {
    if (links.length <= maxVisibleLinks + 1) {
      return {
        visibleLinks: links,
        hiddenLinks: [],
      };
    }
    const visibleLinks = links.slice(0, maxVisibleLinks);
    const hiddenLinks = links.slice(maxVisibleLinks);
    return { visibleLinks, hiddenLinks };
  }, [links, maxVisibleLinks]);

  if (isLoadingHeaderNavItems) {
    return <SkeletonLoader count={maxVisibleLinks} />;
  }

  return (
    <ul
      className="absolute inset-1/2 top-0 flex h-full w-full -translate-x-1/2 list-none items-center justify-center gap-2"
      data-testid="header-navigation-bar"
    >
      {visibleLinks.map(({ linkTo, label, visibility, ...rest }) => (
        <li className="flex" key={linkTo}>
          <NavigationLink
            linkTo={linkTo}
            label={label}
            visibility={visibility}
            {...rest}
          />
        </li>
      ))}
      {hiddenLinks.length > 0 && (
        <li>
          <MorePopover items={hiddenLinks} isPreviewMode={isPreviewMode} />
        </li>
      )}
    </ul>
  );
};
