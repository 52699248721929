import { useRef } from "react";
import { t } from "i18n-js";
import { useHotkeys } from "react-hotkeys-hook";
import { useUnreadChatThreads } from "@circle-react/hooks/chatsV2";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "../../IconButton";
import { BadgeIndicator } from "../BadgeIndicator";

export const ChatThreadsPopoverButton = () => {
  const chatTheadsButtonRef = useRef<HTMLButtonElement>(null);
  const { unreadThreadsCount }: any = useUnreadChatThreads();
  const { isV3Enabled } = useIsV3();

  useHotkeys(
    "alt+t",
    () => {
      chatTheadsButtonRef.current?.click();
    },
    [chatTheadsButtonRef],
  );

  return (
    <div className="relative">
      <TippyV2 interactive={false} content={t("header_v3.chat_threads")}>
        <IconButton
          name={isV3Enabled ? "20-thread-v3" : "16-chat-threads"}
          buttonSize={isV3Enabled ? 36 : 28}
          iconSize={isV3Enabled ? 20 : 16}
          className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover rounded-md"
          iconClassName="!text-c-header"
          ariaLabel={t("header_v3.chat_threads")}
          dataTestId="chat-threads-popover-button"
          ref={chatTheadsButtonRef}
        />
        {unreadThreadsCount > 0 && (
          <BadgeIndicator count={unreadThreadsCount} />
        )}
      </TippyV2>
    </div>
  );
};
