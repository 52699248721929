import type { ReactNode } from "react";
import { useState } from "react";
import classNames from "classnames";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";

const popperOptions = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 5],
      },
    },
  ],
};

export interface ThreeDotsDropdownProps {
  children: ReactNode;
  contentPortalElement?: Element;
}

export const ThreeDotsDropdown = ({
  children,
  contentPortalElement,
}: ThreeDotsDropdownProps) => {
  const canHover = useAnyHoverMediaQuery();
  const [hasDropdownOpen, setHasDropdownOpen] = useState(false);

  return (
    <Dropdown
      dataTestId="three-dots-dropdown-menu"
      button={
        <div className="hover:bg-tertiary flex h-6 w-6 justify-center rounded">
          <Icon
            type="menu-dots"
            className="text-light"
            useWithFillCurrentColor
          />
        </div>
      }
      className={classNames("text-dark z-10 flex justify-end", {
        "group-hover:visible lg:invisible": canHover && !hasDropdownOpen,
      })}
      transitionWrapperClassName="z-50"
      buttonWrapperClassName="flex"
      popperOptions={popperOptions}
      onToggle={setHasDropdownOpen}
      appendTo={contentPortalElement ?? document.body}
    >
      {children}
    </Dropdown>
  );
};
