import { t } from "i18n-js";
import { CounterBadge } from "@circle-react/components/shared/CounterBadge";
import { roundedCount } from "@circle-react/helpers/notificationHelpers";
import { useChatNotification } from "@circle-react/hooks/chatsV2/useChatNotification";
import { Tab } from "@circle-react-uikit/TabV2";
import { DMsList } from "./List";
import { ListWrapper } from "./List/ListWrapper";
import { NoUnreadMessages } from "./NoUnreadMessages";

export const TabHeader = ({ className = "" }) => {
  const { unreadChatRoomUuids = [] } = useChatNotification();
  const unreadChatRoomsCount = unreadChatRoomUuids.length;

  const renderNotificationsCount = () => (
    <CounterBadge count={roundedCount(unreadChatRoomsCount)} />
  );

  return (
    <Tab.Group className="bg-primary overflow-hidden">
      <div className="border-primary border-b px-6">
        <Tab.List>
          <Tab className={className}>{t("messaging.inbox")}</Tab>
          <Tab className={className}>
            <div className="flex items-center gap-1.5">
              {t("messaging.unread")}
              {renderNotificationsCount()}
            </div>
          </Tab>
        </Tab.List>
      </div>
      <Tab.Panels>
        <Tab.Panel>
          <DMsList />
        </Tab.Panel>
        <Tab.Panel>
          {unreadChatRoomsCount === 0 ? (
            <ListWrapper>
              <NoUnreadMessages />
            </ListWrapper>
          ) : (
            <DMsList unreadChatRoomUuids={unreadChatRoomUuids} />
          )}
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};
