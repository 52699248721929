import { createContext, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { isFunction, noop } from "lodash";

const DataTableData = createContext({
  columns: [],
  data: [],
  initialPage: 1,
  isLoading: false,
  onBottomReached: noop,
  onChangePage: noop,
  onClickRow: noop,
  pageSize: 10,
  table: null,
  totalRecordsCount: 0,
});
DataTableData.displayName = "DataTableData";

export const useDataTableDataProvider = () => useContext(DataTableData);

export const DataTableDataProvider = ({
  children,
  columns = [],
  data = [],
  isLoading = false,
  onBottomReached = noop,
  onChangePage = noop,
  onChangeSorting,
  onClickRow,
  initialPage = 1,
  initialSort = null,
  pageSize: pageSizeProp = 10,
  totalRecordsCount = 0,
  groupingEnabled = false,
  grouping = [],
  groupedColumnMode = "remove",
  expanded = false,
}) => {
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: initialPage - 1,
    pageSize: pageSizeProp,
  });

  useEffect(() => {
    setPagination({
      pageIndex: initialPage - 1,
      pageSize: pageSizeProp,
    });
  }, [initialPage, pageSizeProp, setPagination]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const groupingProps = groupingEnabled
    ? {
        getGroupedRowModel: getGroupedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        enableGrouping: groupingEnabled,
        groupedColumnMode,
      }
    : {};

  const groupingState = groupingEnabled ? { grouping, expanded } : {};

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualSortBy: true,
    manualPagination: true,
    pageCount: Math.ceil(totalRecordsCount / pageSize) ?? -1,
    state: {
      pagination,
      ...groupingState,
    },
    initialState: {
      sorting: initialSort,
    },
    onPaginationChange: setPagination,
    ...groupingProps,
  });

  const { sorting: sortingState } = table.getState();

  const [previousIndex, setPreviousIndex] = useState(pageIndex);
  const [previousSorting, setPreviousSorting] = useState(sortingState);

  useEffect(() => {
    if (
      isFunction(onChangeSorting) &&
      sortingState &&
      sortingState !== previousSorting
    ) {
      setPreviousSorting(sortingState);
      onChangeSorting(sortingState);
    }
  }, [onChangeSorting, previousSorting, sortingState]);

  useEffect(() => {
    if (isFunction(onChangePage) && pageIndex !== previousIndex) {
      setPreviousIndex(pageIndex);
      onChangePage(pageIndex + 1);
    }
  }, [onChangePage, pageIndex, previousIndex]);

  const value = useMemo(
    () => ({
      table,
      columns,
      data,
      isLoading,
      onClickRow,
      onChangeSorting,
      totalRecordsCount,
      pageSize,
      onBottomReached,
      onChangePage,
    }),
    [
      table,
      columns,
      data,
      isLoading,
      onClickRow,
      onChangeSorting,
      totalRecordsCount,
      pageSize,
      onBottomReached,
      onChangePage,
    ],
  );

  return (
    <DataTableData.Provider value={value}>{children}</DataTableData.Provider>
  );
};

DataTableDataProvider.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  onBottomReached: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeSorting: PropTypes.func,
  onClickRow: PropTypes.func,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalRecordsCount: PropTypes.number,
};
