import type { MouseEvent } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import type {
  HeaderNavItemKind,
  HeaderNavItemVisibility,
} from "@circle-react/components/SettingsApp/Navigation/hooks/useFetchHeaderNavItems";

export interface NavigationLinkProps {
  linkTo: string;
  label: string;
  isActive?: boolean;
  isDisabled?: boolean;
  dataTestId?: string;
  isFullRounded?: boolean;
  onClick?: () => void;
  openInNewWindow?: boolean;
  kind: HeaderNavItemKind;
  visibility: HeaderNavItemVisibility;
}

export const NavigationLink = ({
  linkTo,
  label,
  isActive = false,
  isDisabled = false,
  dataTestId,
  isFullRounded = true,
  openInNewWindow = false,
  kind,
  visibility,
  onClick = () => {},
}: NavigationLinkProps) => {
  const onClickHandler = (event: MouseEvent<HTMLAnchorElement>) => {
    if (isDisabled) {
      event.preventDefault();
    } else {
      onClick?.();
    }
  };

  const linkState =
    visibility === "admin_only" ? { visibility: "admin_only" } : undefined;

  if (kind !== "custom") {
    return (
      <NavLink
        to={{
          pathname: linkTo,
          state: linkState,
        }}
        onClick={onClickHandler}
        className={active =>
          classNames(
            "text-xs-plus h-8.5 flex items-center px-4 py-2 font-medium transition-colors duration-150",
            "focus-visible:outline-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
            "hover:bg-c-header-hover text-c-header hover:text-c-header focus:text-c-header focus:bg-c-header-hover",
            {
              "!rounded-lg": !isFullRounded,
              "!rounded-full": isFullRounded,
              "!bg-c-header-active hover:!bg-c-header-active !text-c-header-active hover:!text-c-header-active focus:!text-c-header-active":
                isActive ? isActive : active,
            },
          )
        }
        tabIndex={isDisabled ? -1 : 0}
        aria-current={isActive ? "page" : undefined}
        title={label}
        data-testid={dataTestId}
        target={openInNewWindow ? "_blank" : undefined}
        rel={openInNewWindow ? "noopener noreferrer" : undefined}
      >
        {label}
      </NavLink>
    );
  }

  return (
    <a
      href={linkTo}
      onClick={onClickHandler}
      className={classNames(
        "text-xs-plus h-8.5 flex items-center px-4 py-2 font-medium transition-colors duration-150",
        "focus-visible:outline-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        "hover:bg-c-header-hover text-c-header hover:text-c-header focus:text-c-header focus:bg-c-header-hover",
        {
          "!rounded-lg": !isFullRounded,
          "!rounded-full": isFullRounded,
          "!bg-c-header-active hover:!bg-c-header-active !text-c-header-active hover:!text-c-header-active focus:!text-c-header-active":
            isActive !== undefined ? isActive : false,
        },
      )}
      tabIndex={isDisabled ? -1 : 0}
      title={label}
      data-testid={dataTestId}
      target={openInNewWindow ? "_blank" : undefined}
      rel={openInNewWindow ? "noopener noreferrer" : undefined}
    >
      {label}
    </a>
  );
};
