import type {
  HeaderNavItem,
  HeaderNavItemKind,
  HeaderNavItemVisibility,
} from "@circle-react/components/SettingsApp/Navigation/hooks/useFetchHeaderNavItems";
import { HEADER_V3_ROUTES } from "../../constants";

interface NavItem {
  id: number;
  community_id: number;
  name: string;
  url: string;
  open_in_new_window: boolean;
  visibility: HeaderNavItemVisibility;
  kind: HeaderNavItemKind;
}

export const transformHeaderNavItemsToNavItems = (
  items: HeaderNavItem[] | undefined,
  homeLinkTo: string,
): NavItem[] =>
  items?.map((item: HeaderNavItem): NavItem => {
    let url = item.url;
    if (item.kind === "home") {
      url = homeLinkTo;
    } else if (item.kind !== "custom") {
      url = HEADER_V3_ROUTES[item.kind];
    }

    return {
      id: item.id,
      community_id: item.community_id,
      name: item.name,
      url: url ?? "",
      open_in_new_window: item.open_in_new_window,
      visibility: item.visibility,
      kind: item.kind,
    };
  }) ?? [];
