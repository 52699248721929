import PropTypes from "prop-types";
import classnames from "classnames";
import { t } from "i18n-js";
import { toLocaleString } from "@circle-react/helpers/number";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { useDataTableDataProvider } from "../Provider/DataTableDataProvider";

export const Pagination = ({
  className,
  onNext,
  onPrevious,
  nextDisabled,
  previousDisabled,
  align = "justify",
  direction = "normal",
}) => {
  const { table, pageSize, totalRecordsCount, isLoading } =
    useDataTableDataProvider();
  const { pageIndex } = table.getState().pagination;

  const currentPage = pageIndex + 1;
  const from = Math.min(totalRecordsCount, (currentPage - 1) * pageSize + 1);
  const to = Math.min(totalRecordsCount, currentPage * pageSize);
  const total = toLocaleString(totalRecordsCount);

  return (
    <div
      className={classnames(
        "bg-primary flex items-center gap-2 py-3.5 lg:items-center",
        {
          "justify-start gap-x-4":
            (align === "left" && direction === "normal") ||
            (align === "right" && direction === "reverse"),
          "justify-end gap-x-4":
            (align === "left" && direction === "reverse") ||
            (align === "right" && direction === "normal"),
          "justify-between": align === "justify",
        },
        {
          "flex-row-reverse space-x-reverse ": direction === "reverse",
        },
        className,
      )}
    >
      <div>
        <Typography.LabelSm>
          {t("datatable.footer.showing", {
            from,
            to,
            total,
          })}
        </Typography.LabelSm>
      </div>
      <div className="flex gap-2">
        <Button
          variant="secondary"
          onClick={e => {
            if (onPrevious) {
              onPrevious(e, table);
            } else {
              table.previousPage();
            }
          }}
          disabled={
            previousDisabled !== undefined
              ? previousDisabled
              : !table.getCanPreviousPage() || isLoading
          }
        >
          <span className="sm:hidden">
            <Icon type="20-chevron-left" size={20} />
          </span>
          <span className="hidden sm:inline">
            {t("datatable.footer.previous")}
          </span>
        </Button>
        <Button
          variant="secondary"
          onClick={e => {
            if (onNext) {
              onNext(e, table);
            } else {
              table.nextPage();
            }
          }}
          disabled={
            nextDisabled !== undefined
              ? nextDisabled
              : !table.getCanNextPage() || isLoading
          }
        >
          <span className="sm:hidden">
            <Icon type="20-chevron-right" size={20} />
          </span>
          <span className="hidden sm:inline">{t("datatable.footer.next")}</span>
        </Button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  previousDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "justify"]),
  direction: PropTypes.oneOf(["normal", "reverse"]),
  pagination: PropTypes.shape({
    totalRecordsCount: PropTypes.number,
    showingFromPage: PropTypes.number,
    showingToPage: PropTypes.number,
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
  }),
};
