import { useSidebarBorderStyles } from "@circle-react/components/Spaces/SidebarV2/hooks";

export const VerticalSeparator = () => {
  const { borderColor: backgroundColor } = useSidebarBorderStyles();

  return (
    <div
      className="bg-tertiary h-5 w-0.5 rounded-full"
      style={{ backgroundColor }}
    />
  );
};
