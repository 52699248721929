import classnames from "classnames";
import { ChatThreadsList } from "@circle-react/components/Chat/ChatThreads/ChatThreadsList";
import { CommunityFeatureFlagsContextProvider } from "@circle-react/contexts";
import { usePunditUserContext } from "@circle-react/contexts";
import { useUnreadChatThreads } from "@circle-react/hooks/chatsV2";
import { PopoverPortal } from "@circle-react-uikit/PopoverPortal";
import { DEFAULT_POPPER_OPTIONS } from "../constants";
import { ChatThreadsPopoverButton } from "./ChatThreadsPopoverButton";
import { Header } from "./Header";

export const ChatThreads = () => {
  const { currentCommunityMember, isViewOnlyMasquerading } =
    usePunditUserContext();
  const { unreadChatCount } = useUnreadChatThreads();

  const shouldDisplayChatThreads =
    currentCommunityMember?.policies?.can_access_threads_tab ||
    unreadChatCount > 0;

  if (!shouldDisplayChatThreads) {
    return null;
  }

  return (
    <CommunityFeatureFlagsContextProvider>
      <PopoverPortal
        shouldCloseOnLocationChange
        button={<ChatThreadsPopoverButton />}
        className={classnames(
          "border-primary bg-primary h-full max-h-[37rem] w-full max-w-[30rem] rounded-lg border shadow-lg",
          {
            "cursor-not-allowed": isViewOnlyMasquerading,
          },
        )}
        popperOptions={DEFAULT_POPPER_OPTIONS}
        shouldHideOnMdScreens={false}
      >
        <div
          className={classnames("h-full w-full", {
            "pointer-events-none": isViewOnlyMasquerading,
          })}
          data-testid="chat-threads-drawer"
        >
          <Header />
          <div
            className="bg-secondary h-[calc(100%-61px)] w-full overflow-y-auto rounded-b-lg p-3"
            id="chat-threads-header"
          >
            <ChatThreadsList isRenderedOnHeader />
          </div>
        </div>
      </PopoverPortal>
    </CommunityFeatureFlagsContextProvider>
  );
};
