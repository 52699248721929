import classnames from "classnames";
import { useQueryClient } from "react-query";
import { usePunditUserContext } from "@circle-react/contexts";
import { useNotificationWebSocket } from "@circle-react/hooks/notifications/useNotificationWebSocket";
import { PopoverPortal } from "@circle-react-uikit/PopoverPortal";
import { DEFAULT_POPPER_OPTIONS } from "../constants";
import { Body } from "./Body";
import { Header } from "./Header";
import { useNotificationContext } from "./NotificationContext";
import { NotificationPopoverButton } from "./NotificationPopoverButton";
import {
  useNotificationsCount,
  useResetNotificationCount,
} from "./useHeaderNotificationsApi";
import { onNotificationReceive } from "./utils";

export const Popover = () => {
  const { notificationCount, setNotificationCount } = useNotificationContext();

  const queryClient = useQueryClient();
  const { currentCommunityMember, currentCommunity, isViewOnlyMasquerading } =
    usePunditUserContext();

  useNotificationsCount(currentCommunity?.id, setNotificationCount);
  const { mutate: resetNotificationCount } =
    useResetNotificationCount(setNotificationCount);

  useNotificationWebSocket(currentCommunityMember?.id, (eventData: any) =>
    onNotificationReceive({ eventData, queryClient, setNotificationCount }),
  );

  const handleNotificationPopoverOpen = () => {
    if (notificationCount > 0) resetNotificationCount();
  };

  return (
    <PopoverPortal
      shouldCloseOnLocationChange
      button={
        <NotificationPopoverButton notificationCount={notificationCount} />
      }
      className={classnames(
        "border-primary bg-primary h-full max-h-[38rem] w-full max-w-[30rem] rounded-lg border shadow-lg",
        {
          "cursor-not-allowed": isViewOnlyMasquerading,
        },
      )}
      onOpen={handleNotificationPopoverOpen}
      popperOptions={DEFAULT_POPPER_OPTIONS}
      shouldHideOnMdScreens={false}
    >
      <div
        className={classnames("h-full w-full", {
          "pointer-events-none": isViewOnlyMasquerading,
        })}
        data-testid="notifications-drawer"
      >
        <Header />
        <Body />
      </div>
    </PopoverPortal>
  );
};
